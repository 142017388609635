.cardsStudy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
}

.cardsStudy .answerButton {
  margin: 40px 20px;
  min-width: 200px;
  height: 75px;
  font-weight: bold;
  font-size: x-large;
  background-color: #6c989c;
  color: white;
}

.cardsStudy .answerButton:hover {
  background-color: #335153;
}

.cardsStudy .answerInput {
  margin: 40px 20px;
  min-width: 200px;
  height: 75px;
  font-weight: bold;
  font-size: x-large;
}

.cardsStudy
  .answerInput
  .MuiOutlinedInput-root:focus
  .MuiOutlinedInput-notchedOutline,
.cardsStudy
  .answerInput
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.cardsStudy
  .answerInput
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #6c989c;
}

.cardsStudy .checkButton {
  margin-bottom: 22px;
  background-color: #6c989c;
  color: white;
}

.cardsStudy .checkButton:hover {
  background-color: #335153;
}
