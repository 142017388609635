.previews {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;
    justify-items: center;
    padding: 80px 0;
}

.previews.g1 {
    grid-template-columns: repeat(1, 1fr);
}

.previews.g2 {
    grid-template-columns: repeat(2, 1fr);
}