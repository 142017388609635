.profile {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -42%);
  background-color: white;
  padding: 30px 60px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile h1 {
  font-size: x-large;
  font-weight: bold;
  margin: 15px;
}

.profile .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.profile .input {
  width: 350px;
  margin: 15px;
}

.profile .MuiButton-root {
  color: white;
  font-weight: bold;
  font-size: larger;
  text-transform: none;
  margin: 10px;
  width: 300px;
  height: 50px;
}

.profile .saveLogin {
  background-color: #6c989c;
}

.profile .saveLogin:hover {
  background-color: #335153;
}

.profile .changePassword {
  background-color: #6c989c;
}

.profile .changePassword:hover {
  background-color: #335153;
}

.profile .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.profile .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.profile .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #6c989c;
}

.profile .MuiFormLabel-root.Mui-focused {
  color: #6c989c;
}
