.autocomplete {
  width: 300px;
}

.autocomplete .MuiAutocomplete-input {
  color: white;
}

.autocomplete .MuiInput-underline:before {
  border-bottom-color: white;
}

.autocomplete :not(.Mui-disabled) .MuiInput-underline:hover:before {
  border-bottom-color: white;
}

.autocomplete .MuiInput-underline:after {
  border-bottom-color: #9d6649;
}

.autocomplete .MuiAutocomplete-tag {
  color: white;
  background-color: #9d6649;
}

.autocomplete .MuiAutocomplete-tagSizeSmall {
  margin-bottom: 7px;
}

.autocomplete .MuiFormLabel-root,
.autocomplete .MuiFormLabel-root.Mui-focused {
  color: white;
}

.autocomplete span.MuiAutocomplete-tag {
  color: white;
  background-color: transparent;
}

.autocomplete .MuiAutocomplete-tag .MuiChip-deleteIcon {
  color: orangered;
}

.autocomplete .MuiAutocomplete-clearIndicator {
  color: white;
}

.autocomplete .Mui-focused .MuiAutocomplete-clearIndicator {
  color: #9d6649;
}
