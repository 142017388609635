.authorization {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -40%);
  background-color: white;
  padding: 30px 60px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authorization .input {
  width: 350px;
  margin: 15px;
}

.authorization h1 {
  font-size: x-large;
  font-weight: bold;
  margin: 20px;
}

.authorization .MuiButton-root {
  background-color: #6c989c;
  color: white;
  font-weight: bold;
  font-size: larger;
  text-transform: none;
  margin: 20px;
  width: 300px;
  height: 50px;
}

.authorization .MuiButton-root:hover {
  background-color: #335153;
}

.authorization .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.authorization
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.authorization .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #6c989c;
}

.authorization .MuiFormLabel-root.Mui-focused {
  color: #6c989c;
}
