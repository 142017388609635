.cardsPreview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 20px 0;
}

.cardPreview {
  position: relative;
  width: 500px;
  height: 350px;
  margin: 25px;
  border-radius: 30px;
  box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.75), 1px 1px 5px #bec0bd;
}

.cardPreview .sideCard {
  position: relative;
  width: 43%;
  height: 85%;
  border-radius: 30px;
  background-color: white;
  margin: 0px 7px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.cardPreview .sideCard span {
  font-size: medium;
}
