body {
  margin: 0;
  font-family: 'Roboto';
  -ms-overflow-style: none;
  scrollbar-width: none;
  user-select: none;
  background-color: #c0bdbd;
  color: #494f4e;
}

body::-webkit-scrollbar {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: fixed;
  top: 50%;
  left: 48%;
}

.centerText {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: bold;
}
