.settingsBlock {
    background-color: white;
    min-height: 700px;
    border-radius: 50px;
    padding: 25px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.settingsBlock .part1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
}

.settingsBlock .part2 {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
}

.settingsBlock .MuiTextField-root {
    width: 350px;
    margin: 15px;
}

.settingsBlock .button {
    width: 250px;
    background-color: #6C989C;
    margin: 10px;
    border-radius: 15px;
    padding: 0;
    height: 51px;
}

.settingsBlock .button:hover {
    color: white;
    background-color: #335153;
}

.settingsBlock .button .MuiButton-label {
    color: white;
    font-weight: bold;
    font-size: large;
    font-family: 'Roboto';
    text-transform: none;
    padding: 10px 15px;
}

.settingsBlock .MuiDropzoneArea-root {
    width: 350px;
    margin: 15px;
    min-height: 200px;
    font-weight: bold;
    font-family: 'Roboto';
}

.settingsBlock .card {
    width: 400px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.settingsBlock .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.settingsBlock .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.settingsBlock .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #6c989c;
}

.settingsBlock .MuiFormLabel-root.Mui-focused {
  color: #6c989c;
}
