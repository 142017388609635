.header {
  height: fit-content;
  background-color: #335153 !important;
}

.header .toolbar {
  display: grid !important;
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr 1fr;
  align-items: center;
  justify-content: center;
}

.header .toolbar .span2 {
  grid-column-start: span 2;
}

.header .toolbar .logo-container {
  position: relative;
  margin: 10px;
  cursor: pointer;
  transition: all 50ms ease-out;
}

.header .toolbar .logo-container:active {
  transform: translate(0%, 5%);
}

.header .toolbar .logo-container img {
  max-height: 100px;
}

.header .toolbar .logo-container .hov {
  position: absolute;
  left: 0;
  opacity: 0;
}

.header .toolbar .logo-container .hov:hover {
  opacity: 0.75;
}

.header .toolbar .MuiButton-root {
  color: white;
  border-radius: 15px;
  margin: 10px;
  padding: 10px 15px;
  text-transform: none;
  font-size: x-large;
  font-family: 'Roboto';
  font-weight: bold;
  line-height: 1.25;
}

.header .toolbar .MuiButton-root .icon {
  width: 60px;
  height: 60px;
  margin-left: 10px;
}
