.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0px;
    left: 0px;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 250ms ease-in;
}

.overlay:hover {
    opacity: 1;
    border-style: solid;
    border-width: 5px;
    border-color: #6C989C;
}

.overlay .MuiButton-root {
    color: #6C989C;
    border-color: #6C989C;
}

.overlay .MuiButton-root:hover {
    border-color: #6C989C;
}

.overlay .MuiButton-root.active {
    background-color: #6C989C;
    color: white;
}

.overlay .changeButton {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 35px;
    font-weight: bold;
    text-transform: none;
}

.deckPreview {
    position: relative;
    width: 330px;
    height: 550px;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.deckPreview .tags {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    overflow: hidden;
    padding: 10px;
}

.deckPreview .MuiChip-root {
    margin: 5px;
}

.deckPreview .progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}

.deckPreview img {
    max-width: 90%;
    max-height: 90%;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.deckPreview .textBlock {
    max-width: 85%;
    max-height: 85%;
    overflow: hidden;
    font-size: large;
    text-align: center;
}

.deckPreview .name {
    font-weight: bold;
    font-size: x-large;
}